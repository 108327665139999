import React from 'react';
import { List, Segment } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';


const Footer = ({ intl }) => {
  const currentYear = new Date().getFullYear();

  return (
    <Segment role="contentinfo" padded id="footer">
      <div className="footerTop">

        <div className='logoWhiteDiv'>
          <img src='../Logo_White.png' alt='Logo White' className='logoWhite' />
        </div>

        <div>
          <List className="footerList">
            <div role="listitem" className="item footerName">
              <p className="item">
                <FormattedMessage
                  id="Footer-Name"
                  defaultMessage="Bieri Trub"
                />
              </p>
            </div>
            <div role="listitem" className="item">
              <p className="item">
                <FormattedMessage
                  id='Footer-Adresse'
                  defaultMessage="Gfähl 57"
                />
              </p>
            </div>
            <div role="listitem" className="item">
              <p className="item">
                <FormattedMessage
                  id='Footer-Plz-Ort'
                  defaultMessage="3556 Trub"
                />
              </p>
            </div>
          </List>
        </div>
        
        <div>
          <List className="footerList">
          <div role="listitem" className="item">
              <i className="ri-mail-line"></i>
              <a className="item" href="mailto:info@bieritrub.ch">
                <FormattedMessage
                  id='Footer-Email'
                  defaultMessage="info@bieritrub.ch"
                />
              </a>
            </div>
            <div role="listitem" className="item">
              <i className="ri-phone-line"></i>
              <a className="item" href="tel:079 517 09 44">
                <FormattedMessage
                  id='Footer-Telefon-1'
                  defaultMessage="079 517 09 44"
                />
              </a>
            </div>
            <div role="listitem" className="item">
              <i className="ri-phone-line"></i>
              <a className="item" href="tel:034 495 51 27">
                <FormattedMessage
                  id='Footer-Telefon-2'
                  defaultMessage="034 495 51 27"
                />
              </a>
            </div>
          </List>
        </div>
      </div>
      <hr/>
      <div className='footerBottom'>
        <div>
          <List className="footerList">
            <div role="listitem" className="item">
              <a className="item" href="./impressum">
                <FormattedMessage
                  id='Footer-Imprint'
                  defaultMessage="Impressum"
                />
              </a>
            </div>
            <div role="listitem" className="item">
              <a className="item" href="./datenschutz">
                <FormattedMessage
                  id='Footer-privacy'
                  defaultMessage="Datenschutzerklärung"
                />
              </a>
            </div>
            <div role="listitem" className="item">
              <i className="ri-copyright-line"></i>
              {currentYear}&nbsp;
              <FormattedMessage
                id='Footer-Copyright'
                defaultMessage="Bieri Trub"
              />
            </div>
          </List>
        </div>
      </div>
    </Segment>
  );
};

export default injectIntl(Footer);